import React from "react"
import { useHeaderData } from "shared/Hooks/Headers"
import Case from '../../sections/Case';
import {useCaseData} from '../../shared/Hooks/Case';

const CaseShell = () => {
  const data = {
    seo: {
      title: 'Shell deli2go – mała gastronomia na stacjach paliw.',
    },
    cases: useCaseData().shell,
    header: useHeaderData().shell,
  };

  return (
    <Case data={data}/>
  );
}

export default CaseShell
